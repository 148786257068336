<script lang="ts" setup>
const props = defineProps<{
  defaultView: AuthView
}>()

const { components: { navigation: { hideSign } } } = useAppConfig()

const [isOpen, toggleOpen] = useToggle(false)

export type AuthView = 'login' | 'forgottenPassword' | 'register'

const view = ref<AuthView>(props.defaultView)

watch(isOpen, () => {
  if (!isOpen.value) {
    setTimeout(() => {
      view.value = props.defaultView
    }, 300)
  }
})
</script>

<template>
  <div inline>
    <div inline @click="toggleOpen()">
      <slot />
    </div>
    <FDialog v-model="isOpen">
      <SignUpForm v-if="view === 'register'" @change-view="view = $event" @close="isOpen = false" />
      <LoginForm v-if="view === 'login'" :disable-links="hideSign" @change-view="view = $event" />
      <ForgottenPasswordForm v-if="view === 'forgottenPassword'" @change-view="view = $event" @close="isOpen = false" />
    </FDialog>
  </div>
</template>
