<script setup lang="ts">
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { useToast } from 'vue-toastification'
import type { AuthCredentials } from '@base/types'
import type { AuthView } from './widget/WidgetAuthDialogs.vue'

defineProps<{
  disableLinks?: boolean
}>()

const emit = defineEmits<{
  (e: 'changeView', view: AuthView): void
  (e: 'close'): void
}>()

const toast = useToast()
const { loginWithPassword } = useAuth()
const { emailValidation } = useValidations()
const { waitUntilResolve } = useUser()
const { mobileMenuOpen } = useNavigation()

const validationSchema = yup.object({
  email: emailValidation,
  password: yup.string().required('Password is required'),
})

const { meta, isSubmitting, handleSubmit } = useForm({ validationSchema })

const onSubmit = handleSubmit(async values => {
  const error = await loginWithPassword(values as AuthCredentials)

  if (error)
    return toast.error(error.message)

  await waitUntilResolve()
  toast.success('Přihlášení proběhlo úspěšně.')
  navigateTo('/profile/overview')
  emit('close')
  mobileMenuOpen.value = false
})
</script>

<template>
  <div>
    <form @submit="onSubmit">
      <div flex flex-col gap-16px>
        <h4 :data-test="getCypressId('login-header')" heading4 font-800 my-16px>
          Přihlášení
        </h4>
        <FInputVee name="email" cypress-prefix="login" type="text" label="Vaše e-mailová adresa" placeholder="Zadejte Váš e-mail" />
        <FInputVee name="password" type="password" label="Heslo" placeholder="Zadejte Vaše heslo" :show-strength="false" />
        <div v-if="!disableLinks" text-right>
          <button type="button" paragraph-sm to="/" golden-link @click="emit('changeView', 'forgottenPassword')">
            Zapomenuté heslo
          </button>
        </div>
      </div>
      <FButton name="login-login" :disabled="!meta.dirty || !meta.valid" variant="primary" size="lg" w-full mt-48px :loading="isSubmitting" type="submit">
        Přihlásit se
      </FButton>
    </form>

    <div v-if="!disableLinks" mt-32px paragraph-sm text-center>
      Pokud ještě nemáte vytvořený účet,<br><button type="button" golden-link @click="emit('changeView', 'register')">
        Vytvořte si ho nyní
      </button>
    </div>
  </div>
</template>
