<script setup lang="ts">
import * as yup from 'yup'
import { useToast } from 'vue-toastification'
import type { AuthView } from './widget/WidgetAuthDialogs.vue'

const emit = defineEmits<{
  (e: 'changeView', view: AuthView): void
  (e: 'close'): void
}>()

type SignUpView = 'individual' | 'company'
const selectedTab = ref<SignUpView>('individual')
const tabs: { title: string, type: SignUpView }[] = [
  {
    title: 'Fyzická osoba',
    type: 'individual',
  },
  {
    title: 'Právnická osoba',
    type: 'company',
  },
]

const toast = useToast()
const { registerWithPassword } = useAuth()
const { emailValidation, passwordValidation, companyIdNumberValidation } = useValidations()
const { platform } = useAppConfig()

const validationSchema = yup.object({
  name: yup.string().required('Jméno je povinné').min(5, 'Zadejte minimálně 5 znaků'),
  email: emailValidation,
  password: passwordValidation,
  consent: yup.boolean().required().oneOf([true], 'Musíte souhlasit se zpracováním osobních údajů.'),
  isCompany: yup.boolean(),
  companyIdNumber: yup.string().when(
    'isCompany',
    {
      is: true,
      then: () => companyIdNumberValidation,
    },
  ),
  companyName: yup.string().when(
    'isCompany',
    {
      is: true,
      then: () => yup.string().required('Jméno firmy je povinné.').min(6, 'Zadejte minimálně 6 znaků'),
    },
  ),
})

const { meta, setFieldValue, isSubmitting, handleSubmit } = useForm({ validationSchema })

watchEffect(() => {
  setFieldValue('isCompany', selectedTab.value === 'company')
})

const onSubmit = handleSubmit(async values => {
  const error = await registerWithPassword({
    email: values.email,
    password: values.password,
  },
  {
    name: values.name,
    isCompany: values.isCompany,
    companyIdNumber: values.companyIdNumber,
    companyName: values.companyName,
    platformId: platform.id,
    invitedByUserId: useCookie('bitbeli-invitedByUserId').value,
  })

  if (error)
    return toast.error('Zadaný email má již vytvořený účet.')

  emit('close')
  navigateTo('/signup-success')
})
</script>

<template>
  <h4 :data-test="getCypressId('sign-up-header')" heading4 font-800 my-16px>
    Vytvořit nový účet
  </h4>
  <div>
    <div
      pb-0px flex-wrap flex
      gap-4px
      fubex-rounded-md text-left
      font-400 paragraph-sm text-neutral-900
      w-full justify-start items-start
      rounded-b-0 mb-16px
      border-b-3px border-primary-500 dark:border-primary-300
    >
      <div v-for="tab in tabs" :key="tab.type" w-fit>
        <h2
          p-6px px-12px md:py-12px md:px-16px fubex-rounded-md transition-all cursor-pointer duration-250 rounded-b-0
          :class="selectedTab === tab.type
            ? 'tab-active'
            : 'bg-neutral-100 dark:bg-neutral-200 hover:bg-neutral-200 dark:hover:bg-neutral-300' "
          @click="selectedTab = tab.type"
        >
          {{ tab.title }}
        </h2>
      </div>
    </div>
    <form @submit="onSubmit">
      <div v-auto-animate flex flex-col gap-16px>
        <FInputVee v-if="selectedTab === 'company'" name="companyName" cypress-prefix="signup" type="text" label="Firma" placeholder="Zadejte název firmy" />
        <FInputVee v-if="selectedTab === 'company'" name="companyIdNumber" cypress-prefix="signup" type="text" label="IČO" placeholder="Zadejte IČO firmy" />
        <FInputVee name="name" cypress-prefix="signup" type="text" :label="selectedTab === 'individual' ? 'Jméno a příjmení' : 'Jednatel'" placeholder="Zadejte jméno a příjmení" />
        <FInputVee name="email" type="text" label="Vaše e-mailová adresa" placeholder="Zadejte Váš e-mail" />
        <FInputVee name="password" type="password" label="Heslo" placeholder="Zadejte Vaše heslo" />
      </div>
      <div mt-40px flex>
        <FCheckboxVee name="consent">
          Souhlasím se
          <NuxtLink :to="`${platform.domain}/docs/vop.pdf`" target="_blank" basic-link tabindex="-1" @click.stop>
            všeobecnými obchodními podmínkami
          </NuxtLink>
          a se zpracováním
          <NuxtLink :to="`${platform.domain}/docs/gdpr.pdf`" target="_blank" basic-link tabindex="-1" @click.stop>
            osobních údajů
          </NuxtLink>.
        </FCheckboxVee>
      </div>
      <FButton name="signup-register" :disabled="!meta.dirty || !meta.valid" variant="primary" size="lg" w-full mt-48px :loading="isSubmitting" type="submit">
        Vytvořit nový účet
      </FButton>
    </form>

    <div mt-32px paragraph-md text-center>
      Pokud máte již účet, můžete se <button type="button" golden-link @click="emit('changeView', 'login')">
        Přihlásit
      </button>.
    </div>
  </div>
</template>
